import React, { ReactElement, useRef, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";
import Title from "../../components/title/title";
import SubTitle from "../../components/sub-title/sub-title";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PhotoContainer = styled.section`
  margin-top: 40px;
  margin-bottom: 60px;
  ${(props) =>
    props.orientation === "portrait"
      ? `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  `
      : `
    display: flex;
    flex-direction: column;
  `};
`;

const PhotoImage = styled.div``;

const PhotoContent = styled.div`
  text-align: center;
  margin: auto;
  padding: 20px 80px;
`;

const Location = styled.div`
  font-size: 0.7rem;
  font-style: italic;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const PhotoPage = ({ data }): ReactElement => {
  const { title, description, location, image } = data.photo.data;
  const { aspectRatio } = image.localFile.childImageSharp.resize;
  const orientation = aspectRatio > 1 ? "landscape" : "portrait";
  const photo = getImage(data[orientation].data.image.localFile);

  return (
    <Layout>
      <SEO
        title={`${title}, ${location} - Ben Pearey`}
        description={description.text}
        image={photo.images.fallback.src}
      />
      <PhotoContainer orientation={orientation}>
        <PhotoImage>
          <GatsbyImage
            image={photo}
            alt={title}
            imgStyle={{ objectFit: "contain" }}
          />
        </PhotoImage>
        <PhotoContent>
          <Title>{title}</Title>
          <Location>{location}</Location>
          <SubTitle>{description.text}</SubTitle>
        </PhotoContent>
      </PhotoContainer>
    </Layout>
  );
};

export default PhotoPage;

export const query = graphql`
  query ($uid: String!) {
    photo: prismicPhoto(uid: { eq: $uid }) {
      uid
      data {
        title
        description {
          text
        }
        location
        image {
          localFile {
            childImageSharp {
              resize {
                aspectRatio
              }
            }
          }
        }
      }
    }
    landscape: prismicPhoto(uid: { eq: $uid }) {
      uid
      data {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90, placeholder: BLURRED)
            }
          }
        }
      }
    }
    portrait: prismicPhoto(uid: { eq: $uid }) {
      uid
      data {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 480, quality: 80, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
